$(document).on('turbolinks:load', () => {
	loadDataTable();
});

function loadDataTable(){
	$('#singleDataTable').dataTable({
		pagingType: 'simple_numbers',
		columnDefs: [{
			targets: 'no-sort',
			orderable: false
		}],

		language: {
      paginate: {
        first:    'Inicio',
        previous: 'Previo',
        next:     'Siguiente',
        last:     'Final'
      },

      info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
      zeroRecords: "No se encontraron resultados",
      infoFiltered: "(filtrado de _MAX_ registros)",
      infoEmpty: "Sin resultados",
      emptyTable: "Sin registros",
      search: "Buscar",
      lengthMenu: "Mostrar _MENU_ registros",
	   }
	});
}